window.onload = function() {
    parallax = new ParallaxScroller();
    parallax.init();

    setTimeout(() => {
        fetchJSONData('/json/youtube.json', function (error, jsonData) {
            if (error) {
                console.error('Error fetching JSON data:', error);
            } else {
                generateCarouselElements(jsonData);
                initCarouselByCarouselId('carouselSecondaryBig');
                initCarouselByCarouselId('carouselSecondaryThumbnails');
            }
        });           
    }, 1000);

    setTimeout(() => {
        processRSSFeeds();      
    }, 3000);

};

window.onresize = function() {
    ReRenderAllCarousel();
};

function toggleBurgerMenu() {
    
    var sectionHeader = document.getElementById("sectionHeader");
    if(sectionHeader.className.indexOf("opened") == -1)
        sectionHeader.classList.add("opened");
    else 
        sectionHeader.classList.remove("opened");
}

function toggleUnity() {
    toggleActiveClassById('codeContent');
    toggleActiveClassById('bgLayer');
    smoothScrollTo('navigation');
}


// ===============================
// TOOLS
// ===============================


function toggleActiveClassById(id) {
    var domObject = document.getElementById(id);
    if(domObject) {
        if(domObject.className.indexOf("active") == -1)
            domObject.classList.add("active");
        else
            domObject.classList.remove("active");
    }
}


// ===============================
// CUSTOM PARALLAX SCROLL "SYSTEM"
// ===============================

var parallax;
var parallaxSpeedBase = 5000; // lower value is faster --> window.innerHeight/parallaxSpeedBase
var parallaxScrollTimeout;
var parallaxMinimumWindowWidth = 1100;

function ParallaxScroller() {
    this.parallaxElements 
}

ParallaxScroller.prototype.init = function() {

    var body = document.body,
    html = document.documentElement;
    var height = Math.max( body.scrollHeight, body.offsetHeight, 
        html.clientHeight, html.scrollHeight, html.offsetHeight );

    var object = this;
    this.parallaxElements = document.getElementsByClassName("parallax");
    //console.log(this.parallaxElements);
    this.animateScroll();
    setInterval(function() {object.animateSetValues()},100);
}

ParallaxScroller.prototype.animateScroll = function() {
    this.animateSetValues();
}

ParallaxScroller.prototype.animateSetValues = function() {

    for(var i = 0; i < this.parallaxElements.length; i++) {
        var elementSpeed = parseFloat(this.parallaxElements[i].getAttribute("data-parralax-speed"));
        var offsetVh =  parseFloat(this.parallaxElements[i].getAttribute("data-parralax-offset-vh"));
        var animateMobile = this.parallaxElements[i].getAttribute("data-parallax-mobile") != null;

        if(window.innerWidth < parallaxMinimumWindowWidth && animateMobile == false) {
            this.parallaxElements[i].style.transform = "translate(0,0)";
            continue;
        }

        if(!elementSpeed)
            elementSpeed = 0;

        if(!offsetVh)
            offsetVh = 0;

        var distanceFromBottom = (this.parallaxElements[i].getBoundingClientRect().top - window.innerHeight);
        this.parallaxElements[i].style.transform = "translate(0," +
        (((distanceFromBottom * 0.15) * elementSpeed) + (offsetVh*window.innerHeight))
        + "px)";
    }
}

