function smoothScrollTo(elementId) {
    const element = document.getElementById(elementId);
    const offsetHeaderMenu = 50;
    if (element) {
      const offsetTop = element.offsetTop - offsetHeaderMenu;
      const duration = 800; // Adjust this value to control the scroll speed
      
      const startPosition = window.pageYOffset || document.documentElement.scrollTop;
      const distance = offsetTop - startPosition;
      let startTime = null;
    
      function animation(currentTime) {
        if (startTime === null) {
          startTime = currentTime;
        }
    
        const timeElapsed = currentTime - startTime;
        const scrollY = ease(timeElapsed, startPosition, distance, duration);
        window.scrollTo(0, scrollY);
    
        if (timeElapsed < duration) {
          requestAnimationFrame(animation);
        }
      }
    
      // Easing function to make the scroll animation smooth
      function ease(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      }
    
      requestAnimationFrame(animation);
    }
  }